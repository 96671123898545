import {createSlice} from '@reduxjs/toolkit';
import {useNavigate} from "react-router-dom";

const initialState = {
    token: localStorage.getItem('token')
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser(state, action) {
            state.token = action.payload.token;
        },
        removeUser(state) {
            state.token = null;
            // eslint-disable-next-line react-hooks/rules-of-hooks
            localStorage.removeItem('token');
            window.location.href='/login'
        }
    }
});

export const {setUser, removeUser} = userSlice.actions;

export default userSlice.reducer;
