import { Route, Routes } from "react-router-dom";
import React from "react";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import "./scss/style.scss";
import Logout from "./pages/Logout";
import RecoverPage from "./pages/RecoverPage";

function App() {
	return (
		<Routes>
			<Route exact path="/" element={<HomePage></HomePage>}></Route>
			<Route exact path="/login" element={<LoginPage></LoginPage>}></Route>
			<Route exact path="/recover" element={<RecoverPage></RecoverPage>}></Route>
			<Route exact path="/register" element={<RegisterPage></RegisterPage>}></Route>
			<Route exact path="/logout" element={<Logout></Logout>}></Route>
		</Routes>
	);
}

export default App;
