import axios from "axios";
import React, { useState } from "react";

export const FeedbackModal = ({ onClose, token, userId }) => {
	const [feedbackState, setFeedbackState] = useState("");
	const [sendState, setSendState] = useState(false);
	const handleSendFeedbackClick = async () => {
		await axios
			.post(
				"https://api.mydebts.ru/feedback/",
				{
					text: feedbackState,
					id: userId,
				},
				{
					headers: {
						"Authorization-Token": token,
					},
				}
			)
			.then((response) => {
				setSendState(true);
				setTimeout(() => {
					onClose();
				}, 1500);
			})
			.catch((e) => console.error(e));
	};

	return (
		<div id="comment-debt" className="modal-block">
			{sendState ? (
				<>
					<div className="modal-block-title" style={{ margin: "0 auto" }}>
						Сообщение отправлено
					</div>
				</>
			) : (
				<>
					<div className="modal-block-title">Обратная связь</div>
					<div className="modal-input">
						<span>Сообщение</span>
						<textarea
							value={feedbackState}
							onChange={(e) => setFeedbackState(e.target.value)}
							type="text"
							id="edit-debt-comment"
						></textarea>
					</div>

					<div className="modal-buttons">
						<div
							className={`add-button green-btn ${!feedbackState?.length ? "disabled" : ""}`}
							onClick={() => feedbackState?.length && handleSendFeedbackClick()}
						>
							Ok
						</div>
						<div className="cancel-button" onClick={onClose}>
							Отмена
						</div>
					</div>
				</>
			)}
		</div>
	);
};
