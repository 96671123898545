import {useSelector} from 'react-redux';

export function userAuth() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {token} = useSelector(state => state.user);

    return {
        isAuth: !!token,
        token
    }
}
