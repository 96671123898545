import React from "react";
import axios from "axios";
import proComment from "../img/pro_comment.jpg";
import proWidget from "../img/pro_widget.jpg";
import proRepeat from "../img/pro_repeat.jpg";
import proCalendar from "../img/pro_calendar.jpg";
import companyLogo from "../img/logo_w.svg";
import mobileExit from "../img/exit_w.svg";
import calendarArrow from "../img/arrow.svg";
import { Link } from "react-router-dom";
import { FeedbackModal } from "./FeedbackModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DeleteDebtModal } from "./DeleteDebtModal";
import { TryProNotificationModal, handleTryTrialClick } from "./try-trial";

function convertValue(sum) {
	sum = sum ? sum : 0;
	// return sum.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
	return sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
function howMuchDays(year, month) {
	var date1 = new Date(year, month, 1);
	var date2 = new Date(year, month + 1, 1);
	return Math.round((date2 - date1) / 1000 / 3600 / 24);
}
const months = [
	"Январь",
	"Февраль",
	"Март",
	"Апрель",
	"Май",
	"Июнь",
	"Июль",
	"Август",
	"Сентябрь",
	"Октябрь",
	"Ноябрь",
	"Декабрь",
];
const datepattern = /(\d{2})\.(\d{2})\.(\d{4})/;

export default class PersonalPage extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			token: localStorage.getItem("token"),
			bookmark: 0,
			listloaded: false,
			showmodal: false,
			showMobileCalendar: false,
			editData: null,
			oldsum: 0,
			editid: 0,
			editindex: 0,
			modaltype: 0,
			currentDate: new Date(),
			selectmonth: months[new Date().getMonth()],
			selectyear: new Date().getFullYear(),
			isCurrentMonth: true,
			monthDays: [],
			createStartDate: new Date(),
			createCompleteDate: new Date(),
			debts: [],
			user: {
				debet: 0,
				credit: 0,
			},
			calendar: {},
		};
	}

	fetchInfo = () => {
		this.getUserInfo();
		this.getDebts(0);
		this.getCalendar();
	};

	async componentDidMount() {
		this.fetchInfo();
	}

	getUserInfo = async function () {
		const userDataUrl = "https://api.mydebts.ru/rest/user";

		try {
			const userConfig = {
				headers: {
					"Authorization-Token": this.state.token,
				},
				params: {
					token: this.state.token,
				},
			};
			await axios.get(userDataUrl, userConfig).then((response) => {
				this.setState({ user: response.data });
			});
		} catch (err) {
			console.log(err);
			if (err.response) {
				if (err.response.status == 401) {
					window.location.href = "/logout";
				}
			}
		}
	};

	getCalendar = async function () {
		const userDataUrl = "https://api.mydebts.ru/rest/calendar/";
		let nowDate = this.state.currentDate;

		try {
			const userConfig = {
				headers: {
					"Authorization-Token": this.state.token,
				},
				params: {
					month: nowDate.getMonth() + 1,
					year: nowDate.getFullYear(),
				},
			};
			await axios.get(userDataUrl, userConfig).then((response) => {
				this.setState({ calendar: response.data });
				this.createCalendar(response.data.items);
			});
		} catch (err) {
			console.log(err);
		}
	};

	prevCalendarMonth = function () {
		let nowDate = this.state.currentDate;
		nowDate.setMonth(nowDate.getMonth() - 1);
		this.setState({ currentDate: nowDate });
		this.getCalendar();
	};
	nextCalendarMonth = function () {
		let nowDate = this.state.currentDate;
		nowDate.setMonth(nowDate.getMonth() + 1);
		this.setState({ currentDate: nowDate });
		this.getCalendar();
	};
	createCalendar = function (items) {
		let nowDate = this.state.currentDate;
		let monthName = months[nowDate.getMonth()];
		let day = new Date(nowDate.getFullYear() + "-" + (nowDate.getMonth() + 1) + "-01").getDay();
		let days_count = howMuchDays(nowDate.getFullYear(), nowDate.getMonth());
		if (day == 0) {
			day = 7;
		}
		this.setState({
			selectmonth: monthName,
			selectyear: nowDate.getFullYear(),
			isCurrentMonth: false,
		});
		let curDate = new Date();
		if (nowDate.getMonth() == curDate.getMonth()) {
			this.setState({ isCurrentMonth: true });
		}
		let iter = 0;
		let days = [];

		for (let i = 0; i < 42; i++) {
			if (i < day - 1) {
				days.push({ isempty: true });
			} else if (i < days_count + day - 1) {
				iter++;
				let dayplus = 0;
				let dayminus = 0;
				if (items) {
					items.forEach((element, index, array) => {
						let calendarday = new Date(element.dateto.replace(datepattern, "$3-$2-$1"));
						if (calendarday.getDate() == iter) {
							if (element.isdebt == 1) {
								dayminus += element.sum * 1;
							} else {
								dayplus += element.sum * 1;
							}
						}
					});
				}
				let curday = false;
				if (nowDate.getMonth() == curDate.getMonth()) {
					if (nowDate.getDate() == iter) {
						curday = true;
					}
				}
				days.push({
					isempty: false,
					daytitle: iter,
					plus: dayplus,
					minus: dayminus,
					curday: curday,
				});
			} else {
				days.push({ isempty: true });
			}
		}
		this.setState({ monthDays: days });
	};

	getDebts = async function (bmrk) {
		const apiUrl = "https://api.mydebts.ru/rest/debts";
		this.setState({ bookmark: bmrk, listloaded: false });
		let par = {
			isdebt: 1,
			complete: 0,
		};
		if (bmrk === 1) {
			par = {
				isdebt: 0,
				complete: 0,
			};
		} else if (bmrk === 2) {
			par = {
				complete: 1,
			};
		}
		try {
			const config = {
				headers: {
					"Authorization-Token": this.state.token,
				},
				params: par,
			};
			await axios.get(apiUrl, config).then((response) => {
				response.data.items = response.data.items ? response.data.items : [];
				this.setState({ debts: response.data.items, listloaded: true });
			});
		} catch (err) {
			console.log(err);
		}
	};

	updateDebt = async function (data) {
		const userDataUrl = "https://api.mydebts.ru/rest/debts/" + this.state.editid;
		const formatedData = {
			...data,
			dateto: new Date(data.dateto).toLocaleString("ru").split(",")[0],
			datefrom: new Date(data.datefrom).toLocaleString("ru").split(",")[0],
		};
		try {
			const userConfig = {
				headers: {
					"Authorization-Token": this.state.token,
				},
			};
			await axios.put(userDataUrl, formatedData, userConfig).then((response) => {
				this.getDebts(this.state.bookmark);
				this.getCalendar();
				this.setState({ showmodal: false });
			});
		} catch (err) {
			console.log(err);
		}
	};

	updateSum = async function (addsum) {
		let userDataUrl = "https://api.mydebts.ru/rest/debts/" + this.state.editid + "/minus";
		let data = {
			sum: this.state.oldsum,
		};
		if (addsum < 0) {
			userDataUrl = "https://api.mydebts.ru/rest/debts/" + this.state.editid + "/minus";
			data = {
				sum: document.getElementById("sum-value").value,
			};
		} else if (addsum > 0) {
			userDataUrl = "https://api.mydebts.ru/rest/debts/" + this.state.editid + "/plus";
			data = {
				sum: document.getElementById("sum-value").value,
			};
		}
		try {
			const userConfig = {
				headers: {
					"Authorization-Token": this.state.token,
				},
			};
			await axios.put(userDataUrl, data, userConfig).then((response) => {
				this.setState({ showmodal: false });
				this.setState((state) => ({
					debts: state.debts.map((item, j) => {
						// is this the counter that I want to update?
						if (j === this.state.editindex) {
							return {
								...item,
								sum: this.state.oldsum + data.sum * addsum,
							};
						}

						return item;
					}),
				}));
				this.getUserInfo();
				this.getDebts(this.state.bookmark);
				this.getCalendar();
			});
		} catch (err) {
			console.log(err);
		}
	};

	addDebt = async function (addsum) {
		let userDataUrl = "https://api.mydebts.ru/rest/debts";
		let data = {
			title: document.getElementById("my-debt-title").value,
			sum: document.getElementById("my-debt-sum").value,
			datefrom: new Date().toLocaleString("ru").split(",")[0],
			dateto: document.getElementById("my-debt-to").value,
			debt: true,
		};
		if (this.state.user.pro == 1) {
			data.comment = document.getElementById("my-debt-comment").value;
			data.repeat = false;
			if (document.getElementById("my-debt-monthly").checked) {
				data.repeat = true;
			}
		}
		if (this.state.bookmark == 1) {
			data.debt = false;
		}
		try {
			const userConfig = {
				headers: {
					"Authorization-Token": this.state.token,
				},
			};
			await axios.post(userDataUrl, data, userConfig).then((response) => {
				this.setState({ showmodal: false });
				this.getUserInfo();
				this.getDebts(this.state.bookmark);
				this.getCalendar();
			});
		} catch (err) {
			console.log(err);
		}
	};

	render() {
		// const dispatch = this.props.dispatchHook;
		const changeDebt = (ind, openbool) => {
			this.setState((state) => ({
				debts: state.debts.map((item, j) => {
					// is this the counter that I want to update?
					if (j === ind) {
						return {
							...item,
							open: !openbool,
						};
					}

					return item;
				}),
			}));
		};
		const closeModal = () => {
			this.setState({ showmodal: false });
		};
		const editComment = (ind, data, id) => {
			const startDate = data.datefrom.split(" ")[0].split(".");
			const endDate = data.dateto.split(" ")[0].split(".");
			this.setState({
				showmodal: true,
				modaltype: 0,
				editData: {
					...data,
					datefrom: new Date(startDate[2], startDate[1] - 1, startDate[0]),
					dateto: new Date(endDate[2], endDate[1] - 1, endDate[0]),
				},
				editindex: ind,
				editid: id,
			});
		};

		const plusSum = (ind, sum, id) => {
			this.setState({ showmodal: true, modaltype: 1, oldsum: sum, editindex: ind, editid: id });
		};
		const minusSum = (ind, sum, id) => {
			this.setState({ showmodal: true, modaltype: 2, oldsum: sum, editindex: ind, editid: id });
		};
		const completeSum = (ind, sum, id) => {
			this.setState({ showmodal: true, modaltype: 3, oldsum: sum, editindex: ind, editid: id });
		};
		const addDebt = (ind, sum, id) => {
			this.setState({ showmodal: true, modaltype: 4 });
		};
		const feedback = () => {
			this.setState({ showmodal: true, modaltype: 5 });
		};
		const deleteDebet = (ind, data, id) => {
			this.setState({
				showmodal: true,
				modaltype: 6,
				editData: {
					...data,
				},
				editindex: ind,
				editid: id,
			});
		};
		const onTrySuccess = () => {
			this.getUserInfo();
			this.getDebts(this.state.bookmark);
			this.getCalendar();
			this.setState({});
			this.setState({
				...this.state,
				showmodal: true,
				modaltype: 7,
			});
			setTimeout(() => {
				this.setState({
					showmodal: false,
				});
			}, 1500);
		};
		// const setStartDate = (date) => {
		// 	this.setState({ createStartDate: date });
		// };
		const setCompleteDate = (date) => {
			this.setState({ createCompleteDate: date });
		};
		console.log(this.state.editData);
		const modalfield = () => {
			switch (this.state.modaltype) {
				case 0:
					return (
						<div id="add-my-debt" className="modal-block">
							<div className="modal-block-title">Редактирование</div>
							{this.state.editData ? (
								<>
									<div className="modal-input validate">
										<span>Название долга *</span>
										<input
											type="text"
											id="my-debt-edit-title"
											value={this.state.editData.title}
											onChange={(e) =>
												this.setState((state) => ({
													editData: {
														...state.editData,
														title: e.target.value,
													},
												}))
											}
										/>
									</div>
									{/* <div className="modal-input half-block date">
										<span>Дата получения</span>
										<DatePicker
											id="my-debt-edit-from"
											selected={this.state.editData.datefrom}
											dateFormat="dd.MM.yyyy"
											onChange={(date) =>
												this.setState((state) => ({
													editData: {
														...state.editData,
														datefrom: date,
													},
												}))
											}
										/>
									</div> */}
									<div className="modal-input half-block date">
										<span>Дата платежа</span>
										<DatePicker
											id="my-debt-edit-to"
											selected={this.state.editData.dateto}
											dateFormat="dd.MM.yyyy"
											onChange={(date) =>
												this.setState((state) => ({
													editData: {
														...state.editData,
														dateto: date,
													},
												}))
											}
										/>
									</div>

									{this.state.user.pro == 1 ? (
										<div className="pro-input">
											<div className="modal-input">
												<span>Комментарий</span>
												<textarea
													type="text"
													id="my-debt-edit-comment"
													value={this.state.editData.comment}
													onChange={(e) =>
														this.setState((st) => ({
															editData: {
																...st.editData,
																comment: e.target.value,
															},
														}))
													}
												></textarea>
											</div>
											<div className="modal-input">
												<input
													type="checkbox"
													id="my-debt-edit-monthly"
													checked={Boolean(this.state.editData.repeat)}
													onChange={(e) =>
														this.setState((st) => ({
															editData: {
																...st.editData,
																repeat: Number(!Boolean(this.state.editData.repeat)),
															},
														}))
													}
												/>{" "}
												<label htmlFor="my-debt-monthly">Это ежемесячный платеж</label>
											</div>
										</div>
									) : (
										""
									)}
								</>
							) : null}
							<div className="modal-buttons">
								<div
									className={`add-button green-btn ${
										this.state.editData?.title?.length === 0 ? "disabled" : ""
									}`}
									onClick={() => {
										this.state.editData?.title?.length > 0 && this.updateDebt(this.state.editData);
									}}
								>
									Ok
								</div>
								<div
									className="cancel-button"
									onClick={() => {
										closeModal();
									}}
								>
									Отмена
								</div>
							</div>
						</div>
					);
				case 1:
					return (
						<div id="plus-debt" className="modal-block">
							<div className="modal-block-title">Добавить сумму</div>
							<div className="modal-input validate">
								<span>Введите сумму</span>
								<input type="text" id="sum-value" />
							</div>
							<div className="modal-buttons">
								<div
									className="add-button"
									onClick={() => {
										this.updateSum(1);
									}}
								>
									Ok
								</div>
								<div
									className="cancel-button"
									onClick={() => {
										closeModal();
									}}
								>
									Отмена
								</div>
							</div>
						</div>
					);
				case 2:
					return (
						<div id="minus-debt" className="modal-block">
							<div className="modal-block-title">Убавить сумму</div>
							<div className="modal-input validate">
								<span>Введите сумму</span>
								<input type="text" id="sum-value" />
							</div>
							<div className="modal-buttons">
								<div
									className="add-button"
									onClick={() => {
										this.updateSum(-1);
									}}
								>
									Ok
								</div>
								<div
									className="cancel-button"
									onClick={() => {
										closeModal();
									}}
								>
									Отмена
								</div>
							</div>
						</div>
					);
				case 3:
					return (
						<div id="complete-debt" className="modal-block">
							<div className="modal-block-title">Погасить долг?</div>
							<div className="modal-buttons">
								<div
									className="add-button green-btn"
									onClick={() => {
										this.updateSum(0);
									}}
								>
									Погасить
								</div>
								<div
									className="cancel-button"
									onClick={() => {
										closeModal();
									}}
								>
									Отмена
								</div>
							</div>
						</div>
					);
				case 4:
					return (
						<div id="add-my-debt" className="modal-block">
							<div className="modal-block-title">
								{this.state.bookmark == 1 ? "Вам должны" : "Ваш долг"}
							</div>
							<div className="modal-input validate">
								<span>Название долга *</span>
								<input type="text" id="my-debt-title" />
							</div>
							<div className="modal-input validate">
								<span>Сумма долга *</span>
								<input type="text" id="my-debt-sum" />
							</div>
							{/* <div className="modal-input half-block date">
								<span>Дата получения</span>
								<DatePicker
									id="my-debt-from"
									selected={this.state.createStartDate}
									dateFormat="dd.MM.yyyy"
									onChange={(date) => setStartDate(date)}
								/>
							</div> */}
							<div className="modal-input half-block date">
								<span>Дата платежа</span>
								<DatePicker
									id="my-debt-to"
									selected={this.state.createCompleteDate}
									dateFormat="dd.MM.yyyy"
									onChange={(date) => setCompleteDate(date)}
								/>
							</div>
							{this.state.user.pro == 1 ? (
								<div className="pro-input">
									<div className="modal-input">
										<span>Комментарий</span>
										<textarea type="text" id="my-debt-comment"></textarea>
									</div>
									<div className="modal-input">
										<input type="checkbox" id="my-debt-monthly" />{" "}
										<label htmlFor="my-debt-monthly">Это ежемесячный платеж</label>
									</div>
								</div>
							) : (
								""
							)}
							<div className="modal-buttons">
								<div
									className="add-button"
									onClick={() => {
										this.addDebt();
									}}
								>
									Добавить долг
								</div>
								<div
									className="cancel-button"
									onClick={() => {
										closeModal();
									}}
								>
									Отмена
								</div>
							</div>
						</div>
					);
				case 5:
					return (
						<FeedbackModal
							token={this.state.token}
							onClose={() => this.setState({ showmodal: false, modaltype: 5 })}
						/>
					);
				case 6:
					return (
						<DeleteDebtModal
							onSuccess={() => (this.getDebts(this.state.bookmark), this.getCalendar())}
							token={this.state.token}
							debet={this.state.editData}
							onClose={() => this.setState({ showmodal: false, modaltype: 5 })}
						/>
					);
				case 7:
					return <TryProNotificationModal />;
				default:
					return "";
			}
		};

		const bookmarktype = () => {
			switch (this.state.bookmark) {
				case 0:
					return "debts-list-item minus";
				case 1:
					return "debts-list-item plus";
				case 2:
					return "debts-list-item";
			}
		};
		const bookmarktyperepeat = () => {
			switch (this.state.bookmark) {
				case 0:
					return "debts-list-item repeat minus";
				case 1:
					return "debts-list-item repeat plus";
				case 2:
					return "debts-list-item repeat delete";
			}
		};

		return (
			<div>
				<section className="personal">
					<div id="personal-left">
						<div id="personal-left-header">
							<img src={companyLogo} width="160" alt="" />
							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: "24px",

									opacity: 0.4,
									cursor: "pointer",
								}}
							>
								<div onClick={() => feedback()} style={{ width: "24px", height: "24px" }}>
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M6.5371 2.55127C10.17 2.18799 13.83 2.18799 17.4629 2.55127L18.7752 2.6825C20.1391 2.81889 21.2273 3.88057 21.3973 5.2407L21.4216 5.43532C21.8006 8.46664 21.8006 11.5333 21.4216 14.5647C21.2477 15.9559 20.0651 17 18.663 17H13H12.638L12.3598 17.2318L8.00001 20.865L8.00001 18V17H7.00001H5.33707C3.93497 17 2.75229 15.9559 2.57838 14.5647C2.19946 11.5333 2.19946 8.46664 2.57838 5.43532L2.6027 5.2407C2.77272 3.88057 3.86093 2.81889 5.22484 2.6825L6.5371 2.55127ZM17.6619 0.5612C13.8967 0.184678 10.1033 0.184678 6.33809 0.5612L5.02584 0.692426C2.73317 0.921693 0.903937 2.70632 0.618148 4.99263L0.59382 5.18725C0.194313 8.38331 0.194313 11.6167 0.59382 14.8127C0.892836 17.2049 2.92632 19 5.33707 19H6.00001V20.865C6.00001 22.5606 7.97772 23.4869 9.28038 22.4014L13.3621 19H18.663C21.0737 19 23.1072 17.2049 23.4062 14.8127C23.8057 11.6167 23.8057 8.38331 23.4062 5.18725L23.3819 4.99263C23.0961 2.70632 21.2668 0.921692 18.9742 0.692426L17.6619 0.5612ZM8.00001 11C8.55229 11 9.00001 10.5523 9.00001 10C9.00001 9.44771 8.55229 9 8.00001 9C7.44772 9 7.00001 9.44771 7.00001 10C7.00001 10.5523 7.44772 11 8.00001 11ZM12 11C12.5523 11 13 10.5523 13 10C13 9.44771 12.5523 9 12 9C11.4477 9 11 9.44771 11 10C11 10.5523 11.4477 11 12 11ZM17 10C17 10.5523 16.5523 11 16 11C15.4477 11 15 10.5523 15 10C15 9.44771 15.4477 9 16 9C16.5523 9 17 9.44771 17 10Z"
											fill="#fff "
										/>
									</svg>
								</div>
								<div>
									{this.state.user.pro ? "Ваш статус" : "Купить"}
									<div
										id="pro-btn"
										className={this.state.user.pro ? "pro" : ""}
										onClick={() => {
											this.setState({ showMobileCalendar: true });
										}}
									>
										PRO
									</div>
									<Link id="mobile-exit" to={"/logout"}>
										<img src={mobileExit} alt="" />
									</Link>
								</div>
							</div>
						</div>
						<div id="statistics">
							<div className="stat-block minus">
								<span>Я должен</span>
								<b>{convertValue(this.state.user.credit)}</b>
							</div>
							<div className="stat-block plus">
								<span>Мне должны</span>
								<b>{convertValue(this.state.user.debet)}</b>
							</div>
							<div
								className={
									this.state.user.debet - this.state.user.credit > 0
										? "stat-block plus"
										: "stat-block minus"
								}
							>
								<span>Разница</span>
								<b>{convertValue(Math.abs(this.state.user.debet - this.state.user.credit))}</b>
							</div>
						</div>
						<div id="bookmarks">
							<div
								className={this.state.bookmark === 0 ? "bookmark active" : "bookmark"}
								onClick={() => {
									this.getDebts(0);
								}}
							>
								Я должен
							</div>
							<div
								className={this.state.bookmark === 1 ? "bookmark active" : "bookmark"}
								onClick={() => {
									this.getDebts(1);
								}}
							>
								Мне должны
							</div>
							<div
								className={this.state.bookmark === 2 ? "bookmark active" : "bookmark"}
								onClick={() => {
									this.getDebts(2);
								}}
							>
								История
							</div>
							<div
								id="add-button"
								onClick={() => {
									addDebt();
								}}
							>
								Добавить
							</div>
							{this.state.user.pro ? (
								<div
									id="calendar-btn"
									onClick={() => {
										this.setState({ showMobileCalendar: true });
									}}
								></div>
							) : (
								""
							)}
						</div>
						{this.state.listloaded ? (
							this.state.debts.length > 0 ? (
								<div id="debts-list">
									{this.state.debts.map(function (data, index) {
										return (
											<div
												key={index}
												onClick={() => {
													changeDebt(index, data.open);
												}}
												className={data.repeat ? bookmarktyperepeat() : bookmarktype()}
											>
												<div className={data.complete == 1 ? "complete-debt" : ""}>
													<span>{data.title}</span>
													{data.complete == 1 ? (
														<button
															onClick={(e) => (
																e.stopPropagation(), deleteDebet(index, data, data.id)
															)}
															className="delete-btn"
														/>
													) : null}
													<b className="debt-sum">
														{data.complete == 1
															? convertValue(data.fullsum)
															: convertValue(data.sum)}
													</b>
												</div>
												<div className="debts-list-item-date">
													дата платежа <b>{data.dateto.split(" ")[0]}</b>
												</div>

												<div>
													{/* <div
														className="debts-list-show-more-bg"
														onClick={() => {
															changeDebt(index, data.open);
														}}
													></div> */}
													<div
														className={
															data.open ? "debts-list-show-more opened" : "debts-list-show-more"
														}
														onClick={() => {
															changeDebt(index, data.open);
														}}
													></div>
													<div className={data.open ? "debts-list-more opened" : "debts-list-more"}>
														{data.history?.length > 0 ? (
															<div style={{ marginTop: 8 }}>
																{data.history.map((elem) => (
																	<>
																		<div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
																			<div style={{ fontWeight: "300", color: "#fff" }}>
																				{elem.date.split(" ")[0]}
																			</div>
																			<div
																				style={{
																					color:
																						elem.sum.split("")[0] === "-" ? "#ef5252" : "#49e549",
																				}}
																			>{`${elem.sum.split("")[0] === "-" ? "" : "+ "}${convertValue(
																				elem.sum
																			)}`}</div>
																		</div>
																	</>
																))}
															</div>
														) : null}
														{/* <div className="debts-list-item-date">
																дата получения <b>{data.datefrom.split(" ")[0]}</b>
															</div> */}
														<div className="debts-list-comment">{data.comment}</div>
														{data.complete == 0 ? (
															<div className="debts-list-more-buttons">
																<div
																	className="debts-list-more-edit"
																	onClick={() => {
																		editComment(index, data, data.id);
																	}}
																></div>
																<div className="debts-list-more-right">
																	<div
																		className="debts-list-more-plus"
																		onClick={() => {
																			plusSum(index, data.sum, data.id);
																		}}
																	></div>
																	<div
																		className="debts-list-more-minus"
																		onClick={() => {
																			minusSum(index, data.sum, data.id);
																		}}
																	></div>
																	<div
																		className="debts-list-more-all"
																		onClick={() => {
																			completeSum(index, data.sum, data.id);
																		}}
																	>
																		Погасить
																	</div>
																</div>
															</div>
														) : (
															""
														)}
													</div>
												</div>
											</div>
										);
									})}
								</div>
							) : this.state.bookmark !== 2 ? (
								<div id="debts-list-empty">
									{this.state.bookmark === 0 ? (
										<span>Вы пока никому не должны</span>
									) : (
										<span>Вам пока никто не должен</span>
									)}
									<div
										id="add-empty-button"
										onClick={() => {
											addDebt();
										}}
									>
										Добавить долг
									</div>
								</div>
							) : (
								<div id="debts-list-empty">
									<span>У вас пока нет закрытых долгов</span>
								</div>
							)
						) : (
							""
						)}
					</div>
					{this.state.user.pro ? (
						<div id="personal-calendar" className={this.state.showMobileCalendar ? "active" : ""}>
							<div id="personal-right-header">
								<div></div>
								<Link to={"/logout"}>Выйти</Link>
							</div>
							<div id="calendar-title">
								<div className="calendar-month">{this.state.selectmonth}</div>
								<div className="calendar-year">{this.state.selectyear}</div>

								<div
									id="prev-month"
									onClick={() => {
										this.prevCalendarMonth();
									}}
								>
									<img src={calendarArrow} alt="" />
								</div>
								<div
									id="next-month"
									onClick={() => {
										this.nextCalendarMonth();
									}}
								>
									<img src={calendarArrow} alt="" />
								</div>
							</div>
							<div className="calendar-week">
								<span>пн</span>
								<span>вт</span>
								<span>ср</span>
								<span>чт</span>
								<span>пт</span>
								<span>сб</span>
								<span>вс</span>
							</div>

							<div className="calendar-days">
								{this.state.monthDays.map(function (data, index) {
									return data.isempty ? (
										<div className="calendar-day empty" key={index}></div>
									) : (
										<div
											className={
												data.curday
													? data.minus > 0 || data.plus > 0
														? "calendar-day fill current"
														: "calendar-day current"
													: data.minus > 0 || data.plus > 0
													? "calendar-day fill"
													: "calendar-day"
											}
											key={index}
										>
											<span>{data.daytitle}</span>
											<div className="calendar-sums">
												<div className="minus-sum">
													{data.minus > 0 ? convertValue(data.minus) : ""}
												</div>
												<div className="plus-sum">
													{data.plus > 0 ? convertValue(data.plus) : ""}
												</div>
											</div>
										</div>
									);
								})}
							</div>

							<div id="calendar-summary">
								<div className="calendar-summary-minus">
									Вы должны <b>{convertValue(this.state.calendar.credit * 1)}</b>
								</div>
								<div className="calendar-summary-plus">
									Вам должны <b>{convertValue(this.state.calendar.debet * 1)}</b>
								</div>
							</div>
							<div
								id="close-mobile-calendar"
								onClick={() => {
									this.setState({ showMobileCalendar: false });
								}}
							>
								Закрыть
							</div>
						</div>
					) : (
						<div
							id="personal-calendar"
							className={this.state.showMobileCalendar ? "with-scroll active" : "with-scroll"}
						>
							<div id="personal-right-header">
								<div>
									<button
										onClick={() =>
											handleTryTrialClick({
												id: this.state.user?.id,
												onSuccess: onTrySuccess,
											})
										}
										className="try-button"
									>
										Попробовать PRO
									</button>
								</div>
								<Link to={"/logout"}>Выйти</Link>
							</div>

							<div id="why-pro-is-good">
								<h1>Преимущества PRO</h1>
								<div id="pro-advantages-with-banner">
									<div id="pro-advantages">
										<div className="pro-advantage">
											<img src={proWidget} alt="" />
											<div className="pro-advantage-info">
												<b>Полезный виджет для вашего телефона</b>
												Пользователи PRO аккаунта могут воспользоваться удобным виджетом на их
												телефонах. Данный виджет позволит постоянно быть в курсе того, какие долги
												есть в текущем месяце и за все время. Так же виджет выводит информацию о
												ближайшей дате возврата долга.
											</div>
										</div>
										<div className="pro-advantage">
											<img src={proCalendar} alt="" />
											<div className="pro-advantage-info">
												<b>Календарь долгов</b>
												Пользователи PRO аккаунта могут воспользоваться удобным виджетом на их
												телефонах. Данный виджет позволит постоянно быть в курсе того, какие долги
												есть в текущем месяце и за все время. Так же виджет выводит информацию о
												ближайшей дате возврата долга.
											</div>
										</div>
										<div className="pro-advantage">
											<img src={proComment} alt="" />
											<div className="pro-advantage-info">
												<b>Комментарии к долгу</b>
												Пользователи PRO аккаунта могут воспользоваться удобным виджетом на их
												телефонах. Данный виджет позволит постоянно быть в курсе того, какие долги
												есть в текущем месяце и за все время. Так же виджет выводит информацию о
												ближайшей дате возврата долга.
											</div>
										</div>
										<div className="pro-advantage">
											<img src={proRepeat} alt="" />
											<div className="pro-advantage-info">
												<b>Ежемесячные платежи</b>
												Пользователи PRO аккаунта могут воспользоваться удобным виджетом на их
												телефонах. Данный виджет позволит постоянно быть в курсе того, какие долги
												есть в текущем месяце и за все время. Так же виджет выводит информацию о
												ближайшей дате возврата долга.
											</div>
										</div>
									</div>
									<div id="banner"></div>
								</div>
								<div className="pro-fixed-wrapper">
									<button
										id="mobile-close-pro"
										onClick={() => {
											this.setState({ showMobileCalendar: false });
										}}
									>
										Купить PRO
									</button>
									<button
										onClick={() =>
											handleTryTrialClick({
												id: this.state.user?.id,
												onSuccess: () => (
													onTrySuccess(), this.setState({ showMobileCalendar: false })
												),
											})
										}
										className="try-button"
									>
										Попробовать
									</button>
								</div>
							</div>
						</div>
					)}
				</section>

				{this.state.showmodal ? (
					<div id="modal-form" className="active">
						<div className="modal-block-flex">
							<div className="modal-empty"></div>
							{modalfield()}
							<div className="modal-empty"></div>
						</div>
					</div>
				) : (
					""
				)}
			</div>
		);
	}
}
