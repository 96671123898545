import {Link, Navigate, useNavigate} from 'react-router-dom'
import React, {useState} from "react";
import axios from 'axios';
import {useDispatch} from 'react-redux';
import {setUser} from 'store/slices/userSlice';

import companyLogo from '../img/logo.svg';
import {userAuth} from "../hooks/use-auth";

const LoginPage = () => {
    const {isAuth, token} = userAuth();
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const dispatch = useDispatch();
    const push = useNavigate();

    async function handleClick(email, pass) {
        const apiUrl = 'https://api.mydebts.ru/rest/user/auth';
        document.getElementById('auth-error').style.display='none'
        try {
            await axios.post(
                apiUrl,
                {
                    login: email, password: pass
                }
            ).then(response => {
                dispatch(setUser({
                    token: response.data.token
                }));
                localStorage.setItem('token', response.data.token);
                push('/');
            })
        } catch (err) {
            // console.log(err);
            document.getElementById('auth-error').innerHTML = 'Проверьте правильность ввода'
            document.getElementById('auth-error').style.display='block'
        }
    }

    return !isAuth ? (
        <div className="sign">
            <div className="top-img"/>

            <div id="right-block">
                <div id="auth-header"/>
                <div id="login-form">
                    <img src={companyLogo} id="auth-logo" width="200" alt=""/>
                    <b>Добро пожаловать</b>
                    Войдите или зарегистрируйтесь
                    <br/>
                    <br/>
                    <div className="login-input">
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder={"Введите E-mail"}
                        />
                    </div>
                    <div className="login-input">
                        <input
                            type="password"
                            value={pass}
                            onChange={(e) => setPass(e.target.value)}
                            placeholder={"Введите пароль"}
                        />
                    </div>
                    <div id="auth-error" className="auth-error"></div>

                    <div id="auth-buttons">
                        <div id="login-btn" onClick={() => handleClick(email, pass)}>Войти</div>
                        <Link to='/recover'>Забыли пароль</Link>
                    </div>
                    <div id="reg-new-user">Если вы у нас впервые<br></br> можете <Link to="/register">зарегистрироваться</Link></div>
                </div>
                <div id="auth-footer">
                    <span>© {(new Date().getFullYear())} MyDebts</span>
                    <a href="mailto:info@mydebts.ru">Написать нам</a>
                </div>
            </div>
        </div>
    ) : (
        <Navigate to="/" replace={true}></Navigate>
    );
}

export default LoginPage
