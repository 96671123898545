import {Link, Navigate, useNavigate} from 'react-router-dom'
import React, {useState} from "react";
import axios from 'axios';
import {useDispatch} from 'react-redux';

import companyLogo from '../img/logo.svg';
import {userAuth} from "../hooks/use-auth";
import {setUser} from "../store/slices/userSlice";

const RegisterPage = () => {
    const {isAuth, token} = userAuth();
    const push = useNavigate();
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const dispatch = useDispatch();
    const [rpass, setRPass] = useState('');

    function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    async function handleClick(email, pass, rpass) {
        const apiUrl = 'https://api.mydebts.ru/rest/user';
        document.getElementById('auth-error').style.display='none'
        if (!validateEmail(email)){
            document.getElementById('auth-error').innerHTML = 'Проверьте правильность ввода email'
            document.getElementById('auth-error').style.display='block'
        }else if (pass !== rpass || pass===''){
            document.getElementById('auth-error').innerHTML = 'Проверьте правильность ввода паролей'
            document.getElementById('auth-error').style.display='block'
        }else{
            try {
                await axios.post(
                    apiUrl,
                    {
                        email: email, password: pass
                    }
                ).then(response => {
                    if (response.data.error){
                        document.getElementById('auth-error').innerHTML = response.data.error
                        document.getElementById('auth-error').style.display='block'
                    }else{
                        dispatch(setUser({
                            token: response.data.token
                        }));
                        localStorage.setItem('token', response.data.token);
                        push('/');
                    }
                });
            } catch (err) {
                console.log(err);
            }
        }

    }

    return !isAuth ? (
        <div className="sign">
            <div className="top-img"/>

            <div id="right-block">
                <div id="auth-header"/>
                <div id="login-form">
                    <img src={companyLogo} id="auth-logo" width="200" alt=""/>
                    <b>Регистрация</b>
                    <br/>
                    <div className="login-input">
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder={"Введите E-mail"}
                        />
                    </div>
                    <div className="login-input">
                        <input
                            type="password"
                            value={pass}
                            onChange={(e) => setPass(e.target.value)}
                            placeholder={"Введите пароль"}
                        />
                    </div>
                    <div className="login-input">
                        <input
                            type="password"
                            value={rpass}
                            onChange={(e) => setRPass(e.target.value)}
                            placeholder={"Повторите пароль"}
                        />
                    </div>
                    <div id="auth-error" className="auth-error"></div>

                    <div id="auth-buttons">
                        <div id="reg-btn" onClick={() => handleClick(email, pass, rpass)}>Зарегистрироваться</div>
                        <Link to="/login">Войти</Link>
                    </div>
                </div>
                <div id="auth-footer">
                    <span>© {(new Date().getFullYear())} MyDebts</span>
                    <a href="mailto:info@mydebts.ru">Написать нам</a>
                </div>
            </div>
        </div>
    ) : (
        <Navigate to="/" replace={true}></Navigate>
    );
}

export default RegisterPage
