import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import { userAuth } from 'hooks/use-auth';
import {removeUser} from "../store/slices/userSlice";

const Logout = () => {
    const dispatch = useDispatch();
    const {isAuth, token} = userAuth();
    dispatch(removeUser())

    return (
        <div>
        </div>
    )
}

export default Logout
