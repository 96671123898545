import axios from "axios";
import React, { useState } from "react";

export const DeleteDebtModal = ({ onClose, token, debet, onSuccess }) => {
	const [sendState, setSendState] = useState(false);
	
	const handleDeleteDebtClick = async () => {
		await axios
			.delete(`https://api.mydebts.ru/rest/debts/${debet?.id}`, {
				headers: {
					"Authorization-Token": token,
				},
			})
			.then((response) => {
				onSuccess();
				setSendState(true);
				setTimeout(() => {
					onClose();
				}, 1500);
			})
			.catch((e) => console.error(e));
	};

	return (
		<div id="comment-debt" className="modal-block">
			{sendState ? (
				<>
					<div className="modal-block-title" style={{ margin: "0 auto" }}>
						Долг удален
					</div>
				</>
			) : (
				<>
					<div className="modal-block-title">Удалить долг {debet?.title}</div>
					<div className="modal-buttons">
						<div className={`add-button red-btn`} onClick={() => handleDeleteDebtClick()}>
							Ok
						</div>
						<div className="cancel-button" onClick={onClose}>
							Отмена
						</div>
					</div>
				</>
			)}
		</div>
	);
};
