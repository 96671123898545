import React, { useState } from "react";
import axios from "axios";

export const handleTryTrialClick = async ({ id, onSuccess }) => {
	await axios
		.post(
			`https://api.mydebts.ru/rest/user/trial`,
			{
				user_id: id,
			},
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		)
		.then(() => {
			onSuccess();
		})
		.catch((error) => {
			console.log(error);
		});
};

export const TryProNotificationModal = ({ onClose }) => {
	return (
		<div id="comment-debt" className="modal-block" style={{ maxWidth: "650px" }}>
			<>
				<div className="modal-block-title" style={{ margin: "0 auto", textAlign: "center" }}>
					Пробная версия PRO активирована
				</div>
			</>
		</div>
	);
};
