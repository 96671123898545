import React from "react";
import { Navigate } from "react-router-dom";
import { userAuth } from "hooks/use-auth";
import PersonalPage from "../components/PersonalPage";

const HomePage = () => {
	const { isAuth } = userAuth();

	return isAuth ? (
		<div>
			<PersonalPage />
		</div>
	) : (
		<Navigate to="/login" replace={true}></Navigate>
	);
};

export default HomePage;
